<template>
    <div>

        <div class="card-toolbar mb-5">

            <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5" >
                <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
            </button>
        </div>

        <div class="card card-custom mb-5" v-if="showAdvancedSearch">
            <div class="card-body">
                <div class="m-form m-form--fit m--margin-bottom-20">
                    <div class="row mb-5">

                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="job_card_no">{{$t('job_card.job_card_no')}}</label>
                            <input v-model="filters.card_no"  type="text" id="job_card_no" class="form-control">
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="work_order_no">{{$t('job_card.work_order_no')}}</label>
                            <input v-model="filters.work_order_id"  type="text" id="work_order_no" class="form-control">
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="bom_no">{{$t('work_order.bom_no')}}</label>
                            <input v-model="filters.bill_material_id" type="text" id="bom_no" class="form-control">
                        </div>

                         <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="f_status">{{$t('status')}}</label>
                            <select name="" id="f_status" v-model="filters.status" type="text" class="custom-select">
                                <option value="">{{$t('all')}}</option>
                                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.title }}</option>
                            </select>
                        </div>


                        <div class="form-group d-inline-flex col-md-6 mt-6 mb-0">
                            <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                            <span><i class="fas fa-search"></i>
                                <span>{{$t('search')}}</span>
                            </span>
                            </button>
                            <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                            <span><i class="fas fa-trash-restore"></i>
                                <span>{{$t('reset_search')}}</span>
                            </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--begin::customer-->
        <div class="card card-custom">
            <div class="card-body">

                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">


                    <template slot="status" slot-scope="props">
                        <button v-if="$can('job_card.change_status')" :disabled="props.row.status == 3" @click="(props.row.status != 3? changeStatus(props.row.id, props.row.status) : false)" class="btn btn-secondary m-btn m-btn--icon w-auto">
                            {{$t('change_status')}} ({{ props.row.status_name }})
                        </button>
                        <button v-else class="btn btn-secondary m-btn m-btn--icon w-auto">
                            {{$t('change_status')}} ({{ props.row.status_name }})
                        </button>
                    </template>
                    <template slot="actions" slot-scope="props">

                        <v-btn icon  v-if="$can('job_card.update')" color="pink"  :to="`/manufactories/job-card/edit/${props.row.id}`">
                            <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')">mdi-pencil</v-icon>
                        </v-btn>

                        <v-icon small v-if="$can('job_card.delete')" class="text-danger" v-b-tooltip.hover :title="$t('delete')" color="red darken-2"  @click="deleteItem(props.row)">mdi-delete</v-icon>


                    </template>
                </v-server-table>

            </div>
        </div>
        <!--end::customer-->
        <b-modal ref="status-modal" size="md" hide-footer :title="$t('change_status')">
            <change-status-form @hide-modal="hideModal()"
                                :status-list="status_list"
                                :url="routeChangeStatus"
                                :current-id="innerId"
                                :current-status="statusId"
                                @handling-data="getDataAfterChangeStatus">
            </change-status-form>
        </b-modal>
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import ChangeStatusFrom from "@/view/content/forms/ChangeStatusForm";

    export default {
        name: "index-job-card",
        components: {'change-status-form': ChangeStatusFrom,},
        data() {
            return {
                mainRoute: 'manufacturers/job_cards',
                routeChangeStatus: 'manufacturers/job_card/change-status',
                mainRouteDependency: 'base/dependency',

                showAdvancedSearch: false,

                filter: {
                    sortBy: 'id',
                },
                filters: {
                    card_no: null,
                    work_order_id: null,
                    bill_material_id: null,
                    status: null,
                },

                columns: ['code', 'card_no', 'operation_name', 'work_order_id', 'bill_material_id', 'employee_name', 'quantity', 'status', 'actions'],
                data: [],


                status_list :[],
                innerId: null,
                statusId: null,

            }
        },
        computed: {
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        code: that.$t('job_card.job_card_code'),
                        card_no: that.$t('job_card.job_card_no'),
                        operation_name: that.$t('job_card.operation'),
                        work_order_id: that.$t('job_card.work_order_no'),
                        bill_material_id: that.$t('job_card.bom_no'),
                        employee_name: that.$t('job_card.employee'),
                        quantity: that.$t('job_card.quantity'),
                        status: that.$t('status'),
                        actions: that.$t('actions'),
                    },
                    sortable: ['name'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'id'},

                    alwaysShowPerPageSelect: false,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: data.ascending,
                            byColumn: data.byColumn,
                            limit: data.perPage,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(`${that.mainRoute}`, {..._params});

                    },
                    responseAdapter(resp) {
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    }
                }

            },
        },

        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.job_card")}]);
            this.getStatusList();

        },
        methods: {
            showModal() {
                this.$refs['status-modal'].show()
            },
            hideModal() {
                this.$refs['status-modal'].hide();
            },
            changeStatus(id, status) {
                this.innerId = id;
                this.statusId = status;
                this.showModal();
            },
            getDataAfterChangeStatus() {
                this.innerId = null;
                this.statusId = null;
                this.doFilter();
            },

            openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.card_no = null;
                this.filters.work_order_id = null;
                this.filters.bill_material_id = null;
                this.filters.status = null;

                this.$refs.table.refresh();
            },
            getFetch() {
                this.$refs.table.refresh();
            },

            deleteItem(item) {
                this.$confirmAlert('', this.actionDelete, item);
            },

            actionDelete(item) {
                ApiService.delete(`${this.mainRoute}/${item.id}`).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },


            getStatusList() {
                ApiService.get(this.mainRouteDependency + "/work_order_operations_status").then((response) => {
                    this.status_list = response.data.data;
                });
            },
        },
    };
</script>
